import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Utils } from '@services/utils';
import { BaseComponent } from '@abstract/BaseComponent';
import { InputHelper } from '@services/input-helper';
import { Router, ActivatedRoute } from '@angular/router';

interface SearchResult {
  searchParams: {
    routeSearchValue: string;
    carrierSearchValue: string;
  };
  route: string;
  shipments: {
    code: string;
    warpId: number;
  }[];
  carrier?: {
    name?: string;
    mc?: string;
    dot?: string;
  };
  factorCompanyName: string;
  podConfirmation: string;
  grandTotal: number;
  paid: {
    when: string | null;
    transferDate: string | null;
    note: string | null;
  };
  costBreakdown: {
    feeName: string;
    rate?: number;
    quantity?: number | null;
    subtotal: number;
    isNegative: boolean;
    isBaseCost: boolean;
  }[];
  error?: {
    message: string;
    debug: string;
  };
}

@Component({
  selector: 'rate-confirmation',
  templateUrl: './rate-confirmation.component.html',
  styleUrls: ['./rate-confirmation.component.scss']
})
export class RateConfirmationComponent extends BaseComponent implements OnInit {
  searchForm: FormGroup;
  isLoading = false;
  searchResults: SearchResult[] = [];
  error: string = '';
  hasSearched = false;
  public uiVersion: number = 2;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private message: NzMessageService,
    protected router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.searchForm = this.fb.group({
      routeSearchValue: ['', Validators.required],
      carrierSearchValue: ['', Validators.required]
    });

    this.searchForm.get('routeSearchValue')?.valueChanges.subscribe(value => {
      if (value) {
        const trimmedValue = value.toString().trim();
        if (trimmedValue !== value) {
          this.searchForm.patchValue({ routeSearchValue: trimmedValue }, { emitEvent: false });
        }
      }
    });

    this.searchForm.get('carrierSearchValue')?.valueChanges.subscribe(value => {
      if (value) {
        const trimmedStart = value.toString().replace(/^\s+/, '');
        if (trimmedStart !== value) {
          this.searchForm.patchValue({ carrierSearchValue: trimmedStart }, { emitEvent: false });
        }
      }
    });

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) {
        this.uiVersion = 2;
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    
    // Kiểm tra kích thước màn hình khi component khởi tạo
    if (window.innerWidth <= 768) {
      this.uiVersion = 2;
    }
    
    this.route.queryParams.subscribe(params => {
      const { route, carrier } = params;
      if (route || carrier) {
        this.searchForm.patchValue({
          routeSearchValue: route || '',
          carrierSearchValue: carrier || ''
        });
        if (route && carrier) {
          this.onSearch();
        }
      }
    });
  }

  onPaste(event: ClipboardEvent, controlName: string) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text');
    if (pastedText) {
      this.searchForm.patchValue({
        [controlName]: pastedText.trim()
      });
    }
  }

  onSearch() {
    if (!this.searchForm.value.routeSearchValue || !this.searchForm.value.carrierSearchValue) {
      this.error = 'Please enter Route ID and Carrier Information to search';
      return;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        route: this.searchForm.value.routeSearchValue,
        carrier: this.searchForm.value.carrierSearchValue
      },
      queryParamsHandling: 'merge'
    });

    this.isLoading = true;
    this.error = '';
    this.hasSearched = true;
    
    const params = {
      routeSearchValue: this.searchForm.value.routeSearchValue,
      carrierSearchValue: this.searchForm.value.carrierSearchValue
    };
    
    this.apiService.POST('v1/p/route-check', params).subscribe({
      next: (response: any) => {
        this.searchResults = response.data.results;
        if (this.searchResults.length > 0 && this.searchResults[0].error) {
          this.error = this.searchResults[0].error.message;
        }
        this.isLoading = false;
      },
      error: (err) => {
        this.searchResults = [];
        this.error = Utils.getErrorString(err);
        this.isLoading = false;
        console.error(err);
      }
    });
  }

  copyErrorObject(error: any) {
    const errorText = JSON.stringify({
      message: error.message,
      debug: error.debug,
      searchParams: {
        routeSearchValue: this.searchForm.value.routeSearchValue,
        carrierSearchValue: this.searchForm.value.carrierSearchValue
      }
    }, null, 2);
    
    Utils.copyTextToClipboard(errorText, (err) => {
      if (err) {
        this.showErr('Cannot copy error details to clipboard');
      } else {
        this.showSuccess('Error details copied to clipboard');
      }
    });
  }

  getStatusColor(status: any): string {
    if (!status || typeof status !== 'string') {
      return 'default';
    }
    switch (status.toLowerCase()) {
      case 'pending':
        return 'orange';
      case 'inprogress':
        return 'processing';
      case 'completed':
        return 'green';
      default:
        return 'default';
    }
  }

  getPodStatusText(status: any): string {
    if (!status || typeof status !== 'string') {
      return 'No POD Status';
    }
    switch (status.toLowerCase()) {
      case 'pending':
        return 'POD Pending';
      case 'inprogress':
        return 'POD In Progress';
      case 'completed':
        return 'POD Completed';
      default:
        return 'No POD Status';
    }
  }

  getPaymentStatusText(paid: { when: string | null; transferDate: string | null; note: string | null; } | undefined | null): string {
    if (!paid || !paid.when) {
      return 'Payment Pending';
    }
    return 'Payment Completed';
  }

  formatCurrency(value: number | undefined | null, isNegative: boolean = false): string {
    if (value === undefined || value === null) {
      return '0.00';
    }
    const formattedValue = InputHelper.formatMoney2(value.toString());
    return isNegative ? `-${formattedValue}` : formattedValue;
  }

  copyRouteIdToClipboard(text: string): void {
    Utils.copyTextToClipboard(text, (err) => {
        if (err) {
            this.showErr('Failed to copy route');
        } else {
            this.showSuccess('Route ID copied to clipboard');
        }
      });
  }

  public switchUiVersion(version: number): void {
    this.uiVersion = version;
  }
} 